<template>
	<div class="open-account-bank">
		<div class="step-box">
			<div class="step-item">
				<van-image width="20" :src="resourceURL + 'images/step01_h.png'"/>
				<p>填写资料</p>
			</div>
		
			<div class="step-item">
				<img :src="resourceURL + 'images/step_line_h.png'" alt="">
			</div>
		
			<div class="step-item">
				<van-image width="20" :src="resourceURL + 'images/step02_h.png'"/>
				<p>身份验证</p>
			</div>
			<div class="step-item">
				<img :src="resourceURL + 'images/step_line.png'" alt="">
			</div>
			<div class="step-item">
				<van-image width="20" :src="resourceURL + 'images/step03.png'"/>
				<p>提交审核</p>
			</div>
		</div>
		
		<van-form @submit="nextStep()" :label-width="100">

			<h4 class="h4_title">海外银行账户信息</h4>

			<van-field 
				v-model="formItem.country" 
				label="国家" 
				placeholder="请输入"
				:rules="rules.country"
				required
			/>
			
			<van-field 
				v-model="formItem.bank" 
				label="银行" 
				placeholder="请输入"
				:rules="rules.bank"
				required
			/>

			<van-field 
				v-model="formItem.bank_address" 
				label="银行地址" 
				placeholder="请输入"
				:rules="rules.bank_address"
				required
			/>
			<van-field 
				v-model="formItem.account_name" 
				label="账户名" 
				placeholder="请输入"
				:rules="rules.account_name"
				required
			/>
			<van-field 
				v-model="formItem.account_no" 
				label="银行卡号" 
				placeholder="请输入"
				:rules="rules.account_no"
				required
			/>
			<van-field
				v-model="formItem.account_address" 
				label="账户地址" 
				placeholder="请输入"
				:rules="rules.account_address"
				required
			/>

			<van-field
				v-model="formItem.swift_code"
				label="SWIFT CODE"
				placeholder="请输入"
				:rules="rules.swift_code"
				required
			/>
			
			<div class="common_block">
				<van-button type="warning" block native-type="submit">下一步</van-button>
			</div>
		</van-form>
	
	</div>
</template>

<script>
	export default {
		name: 'account_hkbank',
		data() {
			return {
				formItem: {
					country: "",
					bank: "",
					bank_address: "",
					account_name: "",
					account_no: "",
					account_address: "",
					swift_code: "",
				},

				rules: {
					country: [
						{
							required: true,
							message: '请填写国家',
							trigger: 'onBlur'
						}
					],
					bank: [
						{
							required: true,
							message: '请填写银行',
							trigger: 'onBlur'
						}
					],
					bank_address: [
						{
							required: true,
							message: '请填写银行地址',
							trigger: 'onBlur'
						}
					],
					account_name: [
						{
							required: true,
							message: '请填写账户名',
							trigger: 'onBlur'
						}
					],
					account_no: [
						{
							required: true,
							message: '请填写银行卡号',
							trigger: 'onBlur'
						}
					],
					account_address: [
						{
							required: true,
							message: '请填写账户地址',
							trigger: 'onBlur'
						}
					],
					swift_code: [
						{
							required: true,
							message: '请填写SWIFT CODE',
							trigger: 'onBlur'
						}
					],
				},

				token: "",
				
				resourceURL: this.$axios.defaults.resourceURL,
				
				disabledSubmit: true,
				
			}
		},
		mounted() {
			
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.token = this.$route.query.token
				
				this.init()
			}
		},
		methods: {
			init(){
				
				this.$axios({
					method: 'post',
					url: 'account_open/info',
					data: {
						token: this.token
					}
				}).then ((res) => {
					if (res.success) {

						if (res.data.status == 2) {
							//	待审核
							this.$router.replace('/account/success')
						} else if (res.data.status == 3) {
							//	不通过
							this.$router.replace('/account/fail?token=' + this.token)
						} else {
							
							var abroad_data = res.data.abroad_data		
							
							if (abroad_data.length != 0) {
								this.formItem.country = abroad_data.country ? abroad_data.country : ""
								this.formItem.bank = abroad_data.bank ? abroad_data.bank : ""
								this.formItem.bank_address = abroad_data.bank_address ? abroad_data.bank_address : ""
								this.formItem.account_name = abroad_data.account_name ? abroad_data.account_name : ""
								this.formItem.account_no = abroad_data.account_no ? abroad_data.account_no : ""
								this.formItem.account_address = abroad_data.account_address ? abroad_data.account_address : ""
								this.formItem.swift_code = abroad_data.swift_code ? abroad_data.swift_code : ""
								
							}

						}

						
					}else{
						this.$toast.fail(res.error_text)
					}
				})

				
			},

			nextStep(){

				this.$axios({
					method: 'post',
					url: 'account_open/alter',
					data: {
						token: this.token,
						abroad_data: JSON.stringify(this.formItem)
					}
				}).then ((res) => {
					if (res.success) {
						if (res.data.hold_lock == 2) {
							this.$router.push('/account/success')
						} else {
							this.$router.replace('/account/lock?token=' + this.token)
						}
					}else{
						this.$toast.fail(res.error_text)
					}
				})
				
			}
		},
	}
</script>